import { SELL_BIKE_SEARCH_ACTION } from '../../core/constants/actions'
import { sellBikeSearch as searchService } from '../../core/services/bikeServices'

export function sellBikeSearch(searchKey = '') {
  return dispatch => {
    dispatch(request())
    if (searchKey) {
      return searchService(searchKey)
        .then(apiResponse => {
          if (apiResponse.isValid) {
            const sellBikes = apiResponse.mmvResponse
            dispatch(success({
              sellBikes
            }))
          } else {
            dispatch(failure({ error: apiResponse.message }))
          }
        })
    } else {
      dispatch(success({
        sellBikes: []
      }))
    }
  }

  function request() { return { type: SELL_BIKE_SEARCH_ACTION.REQUEST } }
  function success(sellBikes) { return { type: SELL_BIKE_SEARCH_ACTION.SUCCESS, payload: { ...sellBikes } } }
  function failure(error) { return { type: SELL_BIKE_SEARCH_ACTION.FAILURE, payload: { ...error } } }
}

export function resetBikeSearch() {
  return {
    type: SELL_BIKE_SEARCH_ACTION.RESET
  }
}