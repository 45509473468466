import React from 'react'
import ProgressiveImage from 'react-lazy-progressive-image'
import { API_ENDPOINTS } from '../../core/constants/apiEndpoints'
let imageSrcPath= API_ENDPOINTS.IMAGES.IMAGES_BASE_PATH
let defaultImg = imageSrcPath+'/placeholder.svg'

const LazyImage = ({ src, alt, className, ...rest }) => (
  <ProgressiveImage
    placeholder={defaultImg}
    src={src}
  >
    {(loadedSrc) => <img {...rest} src={loadedSrc} alt={alt} className={className} />}
  </ProgressiveImage>
)

export default LazyImage