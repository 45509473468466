import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUrlSanatizedString } from '.././../core/utility'
import franchiseIcon from "../../../shared/assets/images/ownFranchise.svg"
const getUrl = (city, vehicleType) => {
  return city ? `/all-used-bikes-in-${getUrlSanatizedString(city)}` : `/all-used-bikes`
}

const getSellUrl = (city, cityId) => {
  return `/sellbikes`
}

const getExchangeUrl = (city) => {
  return `/sell-your-bike-buy-new-bike`
}

const getServiceUrl = (city) => {
  return `/door-step-service`
}
const getFranchiseUrl = (city) => {
  return `/franchise`
}


const BikeCategory = () => {
  const selectedCity = useSelector(state => state.currentCity.currentCity)
  return (
    <div className="container sectionMarginTop categoryCustomWrapper categoryMobWrapper ">
      {/* {
        selectedCity ?
          <h1 className="font-bold text-center sectionPaddingCategory mb-0">Discover your bike in {selectedCity}</h1>
          : <h1 className="font-bold text-center sectionPaddingCategory mb-0">Discover your bike</h1>
      } */}

      <h1 className="font-bold text-center heading sectionPaddingCategory mb-0" id="category">One Stop For Countless Benefits - Buy At Home</h1>
      <div className="row" id="bikesCategory">
        <div className="col-xs-4 col-sm-3 col-md-3 col-3">
          <Link to={getUrl(selectedCity, 'bikes')} >
            <div className="card h-100 ">
              <div className="card-body newcustomCardPadding text-center ripple">
                <div className="buyBikeIcon"></div>
                <h5 className="font16 font-semibold marginBottomTitle" style={{textTransform:'uppercase'}} id="categorySub">Buy <br className="responsive" /> Used Bikes</h5>
                <p className="card-text xsMob">Buy the best quality used bikes from CredR at a reasonable price.</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xs-4 col-sm-3 col-md-3  col-3">
          <Link to={getSellUrl()} >
            <div className="card h-100">
              <div className="card-body newcustomCardPadding text-center">
                <div className="sellBikeIcon"></div>
                <h5 className="font16 font-semibold marginBottomTitle" style={{textTransform: 'uppercase'}} id="categorySub">Sell <br className="responsive" /> Your Bike</h5>
                <p className="card-text xsMob">Sell your bike to CredR at the best price with instant payment.</p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xs-4 col-sm-3  col-md-3  col-3">
          <Link to={getFranchiseUrl()} > 
            <div className="card h-100">
              <div className="card-body newcustomCardPadding text-center">
              <div className="doorStepIcon ownFranchiseIcon"></div>                
                <h5 className="font16 font-semibold marginBottomTitle" style={{textTransform:'uppercase'}} id="categorySub">own a <br className="responsive" />franchise</h5>
                <p className="card-text xsMob">Launch your own business by owning a franchise with comprehensive support and quick returns.</p>
              </div>
            </div>
          </Link>
        </div>
         <div className="col-xs-4 col-sm-3  col-md-3 col-3">
          <Link to={getExchangeUrl()} > 
            <div className="card h-100">
              <div className="card-body newcustomCardPadding text-center">
                <div className="doorStepIcon"></div>
                <h5 className="font16 font-semibold marginBottomTitle" style={{textTransform:'uppercase'}} id="categorySub">Exchange <br className="responsive" />your bike</h5>
                <p className="card-text xsMob">Exchange your bike seamlessly and book a new bike without any hassle with CredR.</p>
              </div>
            </div>
          </Link>
        </div>
         {/* <div className="col-sm-3 col-3">
          <Link to={getServiceUrl()} > 
            <div className="card h-100">
              <div className="card-body newcustomCardPadding text-center">
                <div className="doorStepIcon"></div>
                <h5 className="font16 font-semibold marginBottomTitle" style={{textTransform:'uppercase'}} id="categorySub">Doorstep <br className="responsive" />Servicing</h5>
                <p className="card-text xsMob">Get any two-wheeler serviced by trained auto experts at a convenient time and location</p>
              </div>
            </div>
          </Link>
        </div> */}
      </div>
    </div>
  )
}

export default BikeCategory