import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './credrmeta.scss'

export default function WhyCredRMetaContent() {
    const [isReadMore, setIsReadMore] = useState(false);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    }
    return (
        <div className="meta-why-content">
            {/* <p> */}
                <h2 className='font-bold'>Why Choose CredR?</h2>
               <p>
               CredR- Your Trusted Used Two-Wheeler brand is a revolutionized platform that solely deals with buying, selling, and<Link to="/sell-your-bike-buy-new-bike" className="font-bold"> exchanging used two-wheelers</Link> /pre-owned bikes. Our mission is to offer best-in-class service with transparency, genuinity, and accuracy.
                {
                    !isReadMore && <span style={{ color: 'red', cursor: 'pointer' }} onClick={toggleReadMore}>...Read More</span>
                }
               </p>
            {/* </p> */}
            {
                isReadMore &&
                <>
                    <p>
                        We operate in the top locations, including Bangalore, Pune, Delhi NCR, Mumbai, Jaipur, Chittorgarh, Faridabad, Bhilwara, Gandhinagar, Noida, Gurgaon, Mysore, Pune, Thane, and Ahmedabad have the foremost collection of pre-owned bikes. The bikes are scrutinized based on the auto expert's 120+ levels of the checkpoints.
                    </p>
                    <p>Browse the top collection of branded used bikes like Yamaha, Suzuki, TVS, Piaggo, KTM, Mahindra, Kawasaki, Royal Enfield, Hero, Honda, Harley Davidson, and many more. The showroom experience is now right at your fingertips by using the CredR app.</p>
                    <ul className='paddingVal'>
                        <li className='font-semibold'>Genuine & Transparent service</li>
                    </ul>
                    <p>
                        CredR assures you to safely sell your bike or buy a used bike online. 100% online means you can self-inspect your motorcycle. There are no hidden charges, all are transparent, and you can save more money and time.
                    </p>
                    <ul className='paddingVal'>
                        <li><b>Finest assorted range </b></li>
                    </ul>
                    <p>
                        We ensure the quality of the used bikes to be finest in all possible ways. Our collection of 100% certified bikes has significantly passed through 120+ levels of checkpoints by the auto experts for retaining top-quality.                    </p>
                    <ul className='paddingVal'>
                        <li><b>Refurbished bikes: As Impressive As A New Two-Wheeler </b></li>
                    </ul>
                    <p>
                        We spend an average amount of nearly Rs.10,000 to Rs. 12,000 on every bike to get it refurbished for maintaining good condition. You'll get a top-quality refurbished used bike at an affordable price.                     </p>
                    <ul className='paddingVal'>
                        <li><b>Swift financing</b></li>
                    </ul>
                    <p>
                        Get hassle-free quick payment done with minimum documentation and no legal issues. We take complete responsibility for the services.                    </p>
                    <ul className='paddingVal'>
                        <li><b>Doorstep service </b></li>
                    </ul>
                    <p>
                        Whether buying a used bike, selling your motorcycle, or even bike servicing, every related thing can be done from the comfort of your home. Opt for self-inspection and get your bike sold from doorstep. You do not need to come to the showroom, as you can now book your preferred time and day online. Take a test drive at 399 INR from home before purchasing a used bike.  Buying or selling a used motorcycle is easy; there are zero additional charges for home delivery, and a free evaluation can be enjoyed.
                    </p>
                    <p>When the generation is confident, the riding experience needs to be fearless. Download the CredR app & enjoy riding on your Dream bike. Book Now!
                    </p>

                    <h2 className='font-bold'>Why should you sell your bike to CredR?</h2>
                    <p>
                        Over the years, CredR has strived to create a fair and engaging customer-friendly service. As a buyer or a seller, you'll feel the ease of enjoying the hassle-free service.
                    </p>
                    <p>For us, quality comes first. Sell your bike to CredR if you want to enjoy a reasonable price. The commoners have repeatedly faced difficulty in selling their old bikes with a genuine deal. We at CredR build up that trust and genuinity with our service. To sell your bike, you can rely on us!
                    </p>
                    <ul className='paddingVal'>
                        <li><b>Sell Bike Online In 3 Quick Steps </b></li>
                    </ul>
                    <p>
                        Submit bike details, get a free evaluation and good deals, and finally get your money credited within 48 hours. The process to <a href='https://www.credr.com/sellbikes'><b>sell bikes online</b> </a>is effortless with CredR. You can sell your bike if you have used bikes or used scooters from brands like Yamaha, Suzuki, TVS, Bajaj, Hero, Honda, Piaggo, Mahindra, and other brands. By submitting your bike details, you'll be directed to fix the time and date for a free inspection of your bike.                    </p>
                    <ul className='paddingVal'>
                        <li><b>Free Inspection by Industry Expert Auto Mechanic</b></li>
                    </ul>
                    <p>
                        Once you have scheduled your bike, an auto expert mechanic will visit your home for a free inspection as per your confirmed time and date.
                    </p>
                    <ul className='paddingVal'>
                        <li><b>Online Self-inspection Available </b></li>
                    </ul>
                    <p>
                        Your health is our priority, and so are your demands! To ease your process to sell bike, you now can opt for a self-inspection through CredR mobile app. By following the step-by-step process, self-inspection can be done in just a few minutes. Submit bike videos and images (as requested) for swift service.
                    </p>
                    <ul className='paddingVal'>
                        <li><b>Get an Accurate Price for your Bike</b></li>
                    </ul>
                    <p>
                        An auto expert at your home will inspect your bike. Once all the parameters or checkpoints are encountered, and no issue is observed, you'll be offered an accurate price for your two-wheeler. The agreed amount will be credited within 48 hours of the deal.

                    </p>
                    <ul className='paddingVal'>
                        <li><b>Hassle-free documentation</b></li>
                    </ul>
                    <p>
                        Regarding CredR, we are known for our swift and trustworthy service. The paperwork holds much importance and is given top priority once the deals are finalized. All the paperwork like RC, license, and bike insurance are shared in time without any headache, and the legal matters are easily sorted.                    </p>
                    <p>
                        Sell your used bike to CredR and enjoy the fast service in no time.
                    </p>
                    <h2 className='font-bold'>Why should you buy a Used Bike from CredR?</h2>
                    <p>
                    Your Trusted Used Two-Wheeler brand-CredR's mission is to ease the buying, selling, servicing, and exchanging of two-wheelers process among the commoners. Offering a banquet of beneficial services to deal with motorcycle requirements and buying the right two-wheeler at a desirable price-CredR makes all impossible possible. By merging with the market auto experts, we strive for detailed scrutiny before purchasing a second hand bike. All the  used bikes and <a href='https://www.credr.com/used-scooters'> <b>used scooters at CredR</b></a>  are refurbished to retain the quality.
                    </p>
                    <ul className='paddingVal'>
                        <li><b>Refurbished Bikes At An Affordable Price</b></li>
                    </ul>
                    <p>
                        Used bikes at CredR are collected from genuine sellers. All the bikes run through 120+ levels of the checkpoint by experienced auto experts. All the bikes are thoroughly checked and tested before purchasing and displaying on our website/app for sale. The used motorcycles are refurbished to retain the top-class quality.
                    </p>
                    <ul className='paddingVal'>
                        <li><b>Take a Test ride at 399 INR from Home Comfort</b></li>
                    </ul>
                    <p>
                        Any of your preferred or chosen bike is eligible for a test ride from your home. Book a test ride at 399 INR before you purchase the used bike. If you are satisfied with the bike, follow the buying process. If you aren't happy with the bike, the test ride amount* will be returned to you in the rarest case.
                    </p>
                    <ul className='paddingVal'>
                        <li><b>Budget-friendly Second Hand Bikes</b></li>
                    </ul>
                    <p>
                        The collection of used bikes accessible at CredR is assorted from the top bike collection brands like Hero, Honda, Royal Enfield, Harley Davidson, KTM, Bajaj, TVS, Mahindra, Kawasaki Suzuki, and Yamaha. All the <a href='https://www.credr.com/all-used-bikes'>used bikes</a> are accessible at an affordable price, and you can choose your favorite bike at a reasonable price in just a few clicks. You can set a filter starting from a below range of 15,000 INR till 2,00,000 INR and purchase your favorite used bike.
                    </p>
                    <ul className='paddingVal'>
                        <li><b>Host of Benefits: 6 Months of Warranty & Free 7 day Buy Protect</b></li>
                    </ul>
                    <p>
                        Securing your bike for the future is CredR's responsibility. We offer best-in-class end-to-end service covering all the critical bike parts like gearbox and engine. The feature of a free 6 months comprehensive warranty worth 5000 INR is an added benefit. Once you purchase a used bike, you'll be covered for your flagship product. You will face many unforeseen issues in a week that will be resolved free of cost. Once you buy a used bike from CredR, there is no worry.
                    </p>
                    <p>
                        CredR ensures 100% genuine service to the customer; offering verified used two-wheelers with a test ride and EMI plans assistance (if required).
                    </p>
                    <h2 className='font-bold'>Why should you choose Bike service at home from CredR?</h2>
                    <p>Bike service is essential to retain your bike's good health. By choosing our doorstep bike service, you'll enjoy a hassle-free experience from the comfort of your home. We help you keep track of your bike service record with our annual maintenance contract. You'll not miss out on your bike servicing at any point throughout the year. </p>
                    <p>Our two bike service plans are: One-time plan of 699 INR and an 1 Year AMC Plan of 1999 INR (4 times bike service). The spare components used on your motorcycle are genuine and of premium quality. We ensure consumer safety with minimal contact while choosing a bike service online. Regular and authorized annual bike service can be done at your home. Choose the plan that best suits your needs.</p>

                    <ul className='paddingVal'>
                        <li><b>Certified Auto experts</b></li>
                    </ul>
                    <p>Your bike will be serviced by certified industry experts. The auto mechanics will provide you with genuine service. They are well-trained & trusted. They will ensure what is wrong with your bike before they start servicing it. Every part of your bike is serviced after your approval. </p>
                    <ul className='paddingVal'>
                        <li><b>Genuine Spare Parts </b></li>
                    </ul>
                    <p>We have assorted mechanics who care for the bike offering quality service. The authentic parts are labeled so you can check them anytime while servicing.</p>
                    <ul className='paddingVal'>
                        <li><b>Sanitized</b></li>
                    </ul>
                    <p>The eco wash service for the bike is the most important these days, and we provide it in both of our plans to make it safe and convenient for you to use without any fear of getting germs/infections.</p>
                    <p>Book bike service at your doorstep today! Get 100% genuine spare parts used for the service by trained auto experts. Once you get the confirmation online, there will be no added charge for being liable for this!</p>
                    <p>We all want a hassle-free service, and CredR promises to offer it to you at your location as per your convenience.</p>


                    {
                        isReadMore && <span style={{ color: 'red', cursor: 'pointer' }} onClick={toggleReadMore}>...Read Less</span>
                    }
                </>
            }
        </div>
    );

}
