import React, { Component } from 'react'
import './home.scss'
import CredRCarousel from '../../components/CredR-Carousel'
import BikeCategory from '../../components/Bike-Category'
import BuyAtHomeMobileDesign from '../../components/BuyAtHomeMobile'
import CredRRatings from '../../components/CredR-Ratings'
import BudgetBikes from '../../components/Budget-Bikes'
import CredRUSP from '../../components/CredR-USP'
import BrandSlider from '../../components/Brand-Slider'
import TopSelling from '../../components/Top-Selling-Bikes'
import Testimonials from '../../components/Testimonials'
import { TESTIMONIALS_CUSTOMERS } from '../../core/constants/testimonials'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { getUrlSanatizedString } from '../../core/utility'
import { HOME } from '../../core/constants/pageConstatnts'
import { setCurrentPage } from '../../redux/actions/currentPageAction'
import { setCurrentCity } from '../../redux/actions/selectedCityAction'
import { removeCity, removeBikeListPayload, getCity } from '../../core/services/storageService'
import HomeFAQs from '../../components/HomeFAQs'
import { withRouter } from 'react-router-dom'
import WhyCredR from '../WhyCredRMetaData'
import CallButton from'../../components/CallButton'

const organizationScheme = {
  "@context": "http://schema.org",
  "@type": "Organization",
  "url": "https://www.credr.com/",
  "sameAs": [
    "https://www.facebook.com/credr/",
    "https://twitter.com/CredrAuto",
    "https://www.youtube.com/channel/UCVIlwyD4kgCJqLYIgOL92mw",
    "https://in.linkedin.com/company/credr",
    "https://www.instagram.com/credr_auto/"
  ],
  "logo": "https://www.credr.com/static/media/crdrlogo.b4d4f53f.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+91-90755 55557",
    "contactType": "customer support",
    "email": "mailto:cs@credr.com"
  }]
}

const GOOGLE_API_KEY = 'AIzaSyCBOYQoLGiRE0cfWgeiHLha9bm5WI4bUp0'

// const FAQsSchema = {
//   "@context": "https://schema.org",
//   "@type": "FAQPage",
//   "mainEntity": [
//     {
//       "@type": "Question",
//       "name": "How can I buy a bike from CredR?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "<p>You can buy a bike from CredR by visiting our website, where you can choose your dream bike, fill in your details and our executive will get in touch with you.</p><p>You can also book a test ride at home by paying Rs. 399 or else you can simply visit your nearby showroom using the <a href=https://www.credr.com/showroom-locator>store locator</a> on the website.</p>"
//       }
//     },
//     {
//       "@type": "Question",
//       "name": "How to sell bikes with CredR?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "Selling bikes with CredR is anyone's cup of tea. You can <a href=https://www.credr.com/sellbikes>sell your bike/scooter at Credr</a> by clicking on the Sell Your Bike option on the webpage. Where once you fill in the necessary information our customer executive will reach out to you. By selling your bike at CredR, you will get an exceptional deal without much hassle or negotiation."
//       }
//     },
//     {
//       "@type": "Question",
//       "name": "CredR has showrooms in what all cities?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "CredR is one of the Most Preferred omnichannel consumer brand for <a href=https://www.credr.com/used-bikes>buying and selling used bikes</a>. Being a pioneer in the largely unorganised industry, CredR has set up multiple showrooms spread across different cities to serve the customers better. The showrooms are located in Bangalore, Bhilwara, Delhi NCR, Hyderabad, Jaipur, Pune and Sikar. Visit the showroom locator page on our website for more details regarding the exact location of showrooms near you."
//       }
//     },
//     {
//       "@type": "Question",
//       "name": "How does CredR doorstep delivery help?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "CredR loves to upgrade according to the needs of our customers. That is the reason behind introducing the Doorstep Delivery feature, using which customers can find a bike on our website and can have a test ride scheduled at home. Post-purchase the bike to be delivered will be sanitised and delivered with as limited contact as possible. By opting for this feature, one can avoid stepping out amidst the pandemic and can buy the bike they want from the comfort of their home."
//       }
//     },
//     {
//       "@type": "Question",
//       "name": "What is CredR Care and why do i need it?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "CredR understands the plight of our customers who have to wait in long queues to get their bikes serviced. With the <a href=https://www.credr.com/door-step-service>CredR Care</a> programme, customers can now finally get their bikes serviced at their doorstep without having to step out. All the bikes under CredR Care are serviced by auto experts at a convenient location and time with maximum protection and minimum contact. This service is available currently across four cities - Bangalore, Delhi NCR, Jaipur and Pune."
//       }
//     },
//     {
//       "@type": "Question",
//       "name": "What types of offers and payment methods are available?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "Customers can unlock a wide range of deals while buying a bike from CredR. Apart from the top-class after-sale services like Assured Buyback, 6 months Warranty, 7-day buy protect, CredR offers festive discounts on used bikes and other regular offers. On the payment front, one can buy any bike by availing an EMI plan."
//       }
//     },
//     {
//       "@type": "Question",
//       "name": "Why should i buy a used bike from CredR?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "In the vast unorganized used bike market, CredR is India’s largest and most trusted consumer brand for buying and selling <a href=https://www.credr.com/all-used-bikes>used bikes and scooters</a> which focuses on customer satisfaction. CredR offers epic quality bikes that go through a 6 level refurbishment & 120 inspection points at the most reasonable prices which helps customers save something extra."
//       }
//     },
//     {
//       "@type": "Question",
//       "name": "What should I do if I face any problems while buying/selling a used bike?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "CredR has set up a dedicated customer support team to guide and ease the work for our customers. A unique customer support executive is allocated to serve our customers better at any point in time pre and post-sale. Patrons can drop in a mail at <a href=mailto:cs@credr.com>cs@credr.com</a> for more assisted support."
//       }
//     },
//     {
//       "@type": "Question",
//       "name": "What are the advantages of buying a second-hand bike?",
//       "acceptedAnswer": {
//         "@type": "Answer",
//         "text": "<a href=https://www.credr.com/used-bikes>Buying a second-hand bike</a> has many advantages. Used bikes are usually sold post service, so by buying a used bike at CredR, customers can avoid spending extra money on bike service. Also, by buying a used bike, patrons can have a quick RC transfer rather than spending days for registration. Apart from this, when customers opt for a used bike, they can own their dream bike by spending a considerably less amount of money."
//       }
//     }
//   ]
// }

class Home extends Component {

  state = {
    userAddress: null,
    latitude: null,
    longitude: null,
  }

  componentDidMount() {
    const { pathname } = this.props.location
    this.props.dispatch(setCurrentPage(HOME))
    if (window.__IS_SSR__) {
      delete window.__IS_SSR__
    } else {
      if (pathname === '/') {
        removeCity()
        this.props.dispatch(setCurrentCity())
      }
      removeBikeListPayload()
      window.scrollTo(0, 0)
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser.")
    }
  }

  getCoordinates = (position) => {
    this.setState({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    })
    this.getUserAddress()
  }

  // handleLocationError = (error) => {
  //   switch(error.code) {
  //     case error.PERMISSION_DENIED:
  //       alert("User denied the request for Geolocation.")
  //       break;
  //     case error.POSITION_UNAVAILABLE:
  //       alert("Location information is unavailable.")
  //       break;
  //     case error.TIMEOUT:
  //       alert("The request to get user location timed out.")
  //       break;
  //     case error.UNKNOWN_ERROR:
  //       alert("An unknown error occurred.")
  //       break;
  //     default: 
  //       alert("An unknown error occurred.")
  //   }
  // }

  getUserAddress = () => {
    const { latitude, longitude } = this.state
    fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
    .then(response => response.json())
    .then(data => this.setState({
      userAddress: data.city
    }))
  }

  componentWillReceiveProps(nextProps) {
    const { history, currentCity } = nextProps
    if (this.props.currentCity !== nextProps.currentCity) {
      history.push(`/${getUrlSanatizedString(currentCity)}`)
    }
  }

  getMetaData = () => {
    const { currentCity, location } = this.props
    const { pathname } = location
    const seoData = {}
    if (pathname === '/Bangalore') {
      seoData.metaTitle = `Second Hand Scooty in Bangalore | Used Scooters for Sale at Best Price - CredR`
      seoData.description = "CredR the most trusted seller and buyer of second hand scooters is offering a sleek used scooty for sale in Bangalore. You’ll get a reasonable update on the used scooter price. Check Today!"
    }
    else if (pathname === '/Pune') {
      seoData.metaTitle = `Second Hand Scooters in Pune | used scooty for sale -CredR`
      seoData.description = "Second Hand Scooters in Pune is a good investment for daily commuting made easier by CredR. This reliable and genuine brand has a range of used scooty for sale in the top locations and effortless benefits for the buyers and sellers. "
    }
    else {
      seoData.metaTitle = `Buy & Sell Used Bikes & Scooters online in ${currentCity ? currentCity : 'India'}`
      seoData.description = "CredR- Buy & Sell Used Bikes & Scooters online in India. Hassle-free selling of your bike. 100% verified refurbished two-wheelers to buy."
    }
    seoData.url = currentCity ? `https://www.credr.com/${getUrlSanatizedString(currentCity)}` : "https://www.credr.com/"
    seoData.keywords = "buy used bike, buy old bikes, buy old scooters, buy secondhand bikes, used bikes for sale, sell used motorcycle, sell used bikes, sell old bike, second hand bikes for sale, best place to buy used bikes, buy used bikes online"
    return seoData
  }

  getCityName = () => {
   if(getCity() && getCity().cityName === 'Bangalore' || getCity() === undefined)  {
     return true
   }
  }

  
  render() {
    const { metaTitle, description, url, keywords } = this.getMetaData()
    const { userAddress} = this.state
    const { device } = this.props
    return (
      <div id="homepage">
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={url} />
          <meta name="keywords" content={keywords} />

          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={url} />
          <meta property="twitter:title" content={metaTitle} />
          <meta property="twitter:description" content={description} />

          <script type="application/ld+json">
            {JSON.stringify(organizationScheme)}
          </script>
          {/* <script type="application/ld+json">
            {JSON.stringify(FAQsSchema)}
          </script> */}
          {
            console.log(device, 'device')
          }
        </Helmet>
        <div className={`${device === 'phone'?"bg-carousal-placeholder-mobile":"bg-carousal-placeholder-desktop"}`}>
            <CredRCarousel 
            userAddress={userAddress}
            />
        </div>
        
        <section className="page-content grey-bg">
            {/* <CallButton
             mobile={device !== 'desktop' ? true : false}
              /> */}
          <BikeCategory />
          {/* {
            device !== 'desktop' && this.getCityName() &&
            <BuyAtHomeMobileDesign />
          } */}
          <CredRRatings />
          <div className="page-container">
            <BudgetBikes />
            <CredRUSP />
            <BrandSlider />
            <TopSelling />
            <Testimonials testimonials={TESTIMONIALS_CUSTOMERS} /><br/>
            <WhyCredR/>
            <HomeFAQs />
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentCity: state.currentCity.currentCity,
  device: state.deviceDetail.device
})

export default connect(mapStateToProps)(withRouter(Home))
