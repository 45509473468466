export const CAROUSEL_SETTINGS = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  slidesToShow: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  // lazyLoad: true
}

// export const CAROUSEL_NEWBIKE_SETTINGS = {
//   dots: true,
//   infinite: true,
//   autoplay: true,
//   autoplaySpeed: 3000,
//   speed: 1000,
//   slidesToShow: 1,
//   pauseOnHover: true,
//   swipeToSlide: true,
//   lazyLoad: true,
//   appendDots: dots => (
//     <div
//       style={{
//         backgroundColor: "green",
//         borderRadius: "10px",
//         padding: "10px"
//       }}
//     >
//       <ul style={{ margin: "0px" }}> {dots} </ul>
//     </div>
//   ),

// }


export const SLIDER_SETTINGS = {
  centerPadding: "60px",
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5.025,
  slidesToScroll: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  lazyLoad: "progressive",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4.025
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3.025,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.025,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
      }
    }
  ]
}

export const POPULAR_BIKE_SETTINGS = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4.025,
  slidesToScroll: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  lazyLoad: "ondemand",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3.025
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3.025
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2.025
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.25
      }
    }
  ]
}

export const TESTIMONIALS_SETTINGS = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3.025,
  slidesToScroll: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  responsive: [

    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2.025,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.025,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.25,
      }
    }
  ]
}

export const TESTIMONIALS_SETTINGS_ENHANCED = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2.20,
  slidesToScroll: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 1.2,
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1.025,
      }
    },
    {
      breakpoint: 489,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
}

export const SIMILARBIKE_SETTINGS = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4.025,
  slidesToScroll: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  lazyLoad: "ondemand",
  responsive: [
    {
      breakpoint: 1359,
      settings: {
        slidesToShow: 3.5
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3.025
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3.025
      }
    },
    {
      breakpoint: 870,
      settings: {
        slidesToShow: 2.25
      }
    },
    {
      breakpoint: 679,
      settings: {
        slidesToShow: 1.5
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.25
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1.12
      }
    }
  ]
}

export const NEARBY_STORE_SETTINGS = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3.5,
  slidesToScroll: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  responsive: [

    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2.5,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.5,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
      }
    }
  ]
}

export const SLIDER_CALENDAR_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 12.25,
  slidesToScroll: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  className: 'date-slider',
  responsive: [

    {
      breakpoint: 760,
      settings: {
        slidesToShow: 10.25,
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 8.25,
      }
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 6.25,
      }
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 4.25,
      }
    },
    {
      breakpoint: 359,
      settings: {
        slidesToShow: 4.25,
      }
    },
    {
      breakpoint: 340,
      settings: {
        slidesToShow: 3.25,
      }
    }
  ]
}


export const SLIDER_CALENDAR_SETTINGS_NEW_SELL = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5.25,
  slidesToScroll: 1,
  pauseOnHover: true,
  swipeToSlide: true,
  className: 'date-slider',
  responsive: [

    {
      breakpoint: 1760,
      settings: {
        slidesToShow: 5,
      }
    },
    {
      breakpoint: 999,
      settings: {
        slidesToShow: 4.25,
      }
    },
    {
      breakpoint: 899,
      settings: {
        slidesToShow: 3.25,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 5.25,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4.25,
      }
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: 5.50,
      }
    },
    {
      breakpoint: 390,
      settings: {
        slidesToShow: 4.50,
      }
    },
    {
      breakpoint: 340,
      settings: {
        slidesToShow: 3.50,
      }
    }
  ]
}