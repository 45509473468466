import React from 'react'
import { getAmount } from '../../../core/utility'
import LazyImage from '../../Lazy-Image'
import Zoom from 'react-reveal/Zoom'

const PopularBikeCard = ({ bike, onClick }) => (
  <Zoom>
    <div className="card" id="bikeCardLink" onClick={onClick}>
      <div className="cardBikeImgWrapper" >
        <LazyImage src={bike.imageUrlList && bike.imageUrlList.length && bike.imageUrlList[0]} alt={`${bike.vehicleName}`} className='card-img-top' />
        <div className="credrWarranty"></div>
      </div>
      <div className="card-body" >
      <h3 className="font-semibold font16 bikeHdMarginBtm">{bike.bikeMakeName} {bike.bikeModelName}</h3>
        <h3 className="font-semibold font16">  Starting @ &#8377;{getAmount(bike.price)}</h3>
        <p className="card-text font13 font-light"> {bike.count} Available Bikes</p>
      </div>
    </div>
  </Zoom>
)

export default PopularBikeCard