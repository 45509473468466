import { SET_THANK_YOU, RESET_THANK_YOU } from '../../core/constants/actions'

export function setThankYouData(formData) {
  return dispatch => {
    dispatch({
      type: SET_THANK_YOU,
      payload: { formData }
    })
  }
}

export function resetThankYouData() {
  return dispatch => {
    dispatch({
      type: RESET_THANK_YOU
    })
  }
}