import React from 'react'

const USPCard = ({ icon, heading, discription }) => (
  <div className="card h-100 d-inline-block">
    <div className="card-body customCardPadding text-center">
      <div className={icon}></div>
      <h3 className="card-title font20 font-semibold marginBottomTitle">{heading}</h3>
      <p className="card-text font-light">{discription}</p>
    </div>
  </div>
)

export default USPCard