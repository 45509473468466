import http from 'axios'
import { handleError, handleResponse } from '../utility'
import { API_ENDPOINTS } from '../constants/apiEndpoints'

const API_ENDPOINT_CREATE_LEAD = API_ENDPOINTS.LEAD.CREATE_LEAD
const API_ENDPOINT_CREATE_FRANCISE_LEAD = API_ENDPOINTS.LEAD.FRANCHISE_LEAD
const API_ENDPOINT_CREATE_SELL_LEAD = API_ENDPOINTS.LEAD.CREATE_SELL_LEAD
const API_ENDPOINT_CREATE_SELL_LEAD_V2 = API_ENDPOINTS.LEAD.CREATE_SELL_LEAD_V2
const API_ENDPOINT_VALIDATE_RTO_CODES = API_ENDPOINTS.LEAD.VALIDATE_RTO_CODES
const API_ENDPOINT_UPDATE_SELL_LEAD_V2 = API_ENDPOINTS.LEAD.UPDATE_SELL_LEAD
const API_ENDPOINT_GET_SELL_LEAD_V2 = API_ENDPOINTS.LEAD.GET_SELL_LEAD
const API_ENDPOINT_UPDATE_LEAD = API_ENDPOINTS.LEAD.UPDATE_LEAD
const API_ENDPOINT_GET_REASON = API_ENDPOINTS.LEAD.GET_REASONS
const API_ENDPOINT_GET_MAKES_SELL = API_ENDPOINTS.LEAD.GET_SELL_BRANDS
const API_ENDPOINT_CREATE_EXCHANGE_LEAD = API_ENDPOINTS.LEAD.EXCHANGE_LEAD
const API_ENDPOINT_UPDATE_SELL_AD_LEAD = API_ENDPOINTS.LEAD.UPDATE_SELL_ADS_LEAD
const API_ENDPOINT_CREATE_BUY_ADS_LEAD = API_ENDPOINTS.LEAD.BUY_ADS_LEAD
const API_ENDPOINT_SELL_UPDATE_MOBILE_NUMBER = API_ENDPOINTS.MISC.UPDATE_MOBILE_NUMBER
const API_ENDPOINT_GET_BUY_ADS_BANNER=API_ENDPOINTS.LEAD.GET_ADS_BANNER

function createLead(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_CREATE_LEAD, lead, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function createBuyAdsLead(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_CREATE_BUY_ADS_LEAD, lead, {headers})
  .then(handleResponse)
  .catch(handleError)
}

function createSellLead(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_CREATE_SELL_LEAD, lead, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function updateMobileLead(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_SELL_UPDATE_MOBILE_NUMBER, lead, {headers})
    .then(handleResponse)
    .catch(handleError)
}


function createSellLeadV2(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_CREATE_SELL_LEAD_V2, lead, {headers})
    .then(handleResponse)
    .catch(handleError)
}
function validateRTOCodes(payload) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_VALIDATE_RTO_CODES, payload, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function updateSellLeadV2(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.put(API_ENDPOINT_UPDATE_SELL_LEAD_V2, lead, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function updateSellAdsLead(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_UPDATE_SELL_AD_LEAD, lead, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getSellLead(leadId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_SELL_LEAD_V2.replace('<LEAD_ID>', leadId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function createFranchiseLead(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_CREATE_FRANCISE_LEAD, lead, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function createExchangeLead(info) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_CREATE_EXCHANGE_LEAD, info, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function updateLead(lead) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.post(API_ENDPOINT_UPDATE_LEAD, lead, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getReasons() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  return http.get(API_ENDPOINT_GET_REASON, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getMakesForSell(reasonId) {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_MAKES_SELL.replace('<REASON_ID>', reasonId)
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

function getBuyAdsBanner() {
  const headers = {}
  if(typeof window !== 'undefined'){
    const devId = window.localStorage.getItem('devId')
    if(devId){
      headers["dev-id"] = devId
    }
  }
  const url = API_ENDPOINT_GET_BUY_ADS_BANNER
  return http.get(url, {headers})
    .then(handleResponse)
    .catch(handleError)
}

export {
  createLead,
  createFranchiseLead,
  createSellLead,
  updateLead,
  createSellLeadV2,
  validateRTOCodes,
  updateSellLeadV2,
  updateSellAdsLead,
  getSellLead,
  getReasons,
  getMakesForSell,
  createExchangeLead,
  createBuyAdsLead,
  updateMobileLead,
  getBuyAdsBanner
}