import React, { Component, Fragment } from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import { SLIDER_SETTINGS } from '../../core/constants/settings'
import { connect } from 'react-redux'
import LazyImage from '../Lazy-Image'
import { getAllBrands } from '../../redux/actions/brandAction'
import { getUrlSanatizedString } from '../../core/utility'

class BrandSlider extends Component {

  componentDidMount() {
    this.props.dispatch(getAllBrands())
  }

  getBrandUrl(city, brand) {
    return city ? `/all-used-bikes-in-${getUrlSanatizedString(city)}/${getUrlSanatizedString(brand)}` : `/all-used-bikes/${getUrlSanatizedString(brand)}`
  }

  render() {
    const { currentCity, brands } = this.props
    return (
      <div className="sectionCustomMarginTop" >
        <div className="row">
          <div className="col-sm-12 homepagePaddingMuted">
            {
              currentCity ?
                <h2 className="font-bold text-center sectionCustomPaddingBtm mb-0">Search By Brands in {currentCity}</h2>
                : <h2 className="font-bold text-center sectionCustomPaddingBtm mb-0">Search By Brands</h2>
            }
            <div className="card ">
              <div className="card-body cardConatiner" id="serachByBrands">
                <div className="text-center">
                  <Slider {...SLIDER_SETTINGS} className="slider">
                    {
                      brands.length ? (brands.map(brand => {
                        return (
                          <div className="brandCard" key={brand.bmId}>
                            <div className="card h-100" id="bikeCardLink">
                              <Link to={this.getBrandUrl(currentCity, brand.bmName)} >
                                <div className="card-body text-center">
                                  <LazyImage src={brand.makeImageUrl} alt={brand.bmName} className="bikesLogo" />
                                  <div className="w-100 text-uppercase font-semibold font16 text-center" >{brand.bmName}</div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })) : <Fragment />
                    }
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentCity: state.currentCity.currentCity,
  brands: state.brand.brandList
})

export default connect(mapStateToProps)(BrandSlider)
