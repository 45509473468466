import femaleIcon from '../../assets/images/fm-avatar.svg'
import maleIcon from '../../assets/images/m-avatar.svg'
import { API_ENDPOINTS } from '../../core/constants/apiEndpoints'
import Divakar from '../../assets/images/divakar.jpg'
import Siva from '../../assets/images/siva.jpg'
import Abhi from '../../assets/images/abhi.jpg'

let imageSrcPath = API_ENDPOINTS.IMAGES.IMAGES_BASE_PATH
let imageSrcUserPath = API_ENDPOINTS.IMAGES.IMAGE_USER_DOCS_PATH

let TestimonialIcon = imageSrcPath + "/Pallavi.jpg";
let TestimonialIcon1 = imageSrcPath + "/Vijay.jpg";
let TestimonialIcon2 = imageSrcPath + "/Lalita.jpg";
let TestimonialIcon3 = imageSrcPath + "/Amol.jpg";
let TestimonialIcon4 = imageSrcPath + "/Akhilesh.jpg";

let ServiceTestimonialYash = imageSrcUserPath + "/1646725609133-Yash.jpeg"
let ServiceTestimonialMithesh = imageSrcUserPath + "/1646736808599-Mithesh.jpeg"
let ServiceTestimonialKratikJain = imageSrcUserPath + "/1646736924202-kratik_jain.jpeg"
let ServiceTestimonialArun = imageSrcUserPath + "/1646736973734-Arun.jpeg"
let ServiceTestimonialKamal = imageSrcUserPath + "/1646737007525-Kamal.jpeg"



export const TESTIMONIALS_CUSTOMERS = [
  {
    name: 'Divakar Naik',
    profile: Divakar,
    comment: 'Buying through a third party was worrying, then I came to know about Credr. They helped me to get the choice of my vehicle in my range. The vehicle was delivered in nice condition. They helped me get RC transfer smoothly as well. I am enjoying the ride. Thanks, CredR. Thanks',
    id: 'Divakar'
  },
  {
    name: 'Siva mani',
    profile: Siva,
    comment: "I want to share my experience in buying a second hand bike in Bangalore. I recently moved to Bangalore from TN, and I don't have any contact in Bangalore. Then I came to know about credr from my friend in TN and was surprised that they are working all over India. I got in touch with the customer executive, and they asked me the requirements and price range and what I preferred. Within 2days they came with a good deal with what I wanted and the price too. The bike was in very good condition, and all documents were readily done. I got my RC within 15 days, thanks to the team for the amazing service.",
    id: 'Siva'
  },
  {
    name: 'Abhi Das',
    profile: Abhi,
    comment: "I purchased a Bajaj pulsar last month, happy with their service so far, good going big thanks to the team; they helped me a lot, got 3 vehicle test rides and then finally chose one they suggested to me- an awesome bike. I got my RC card within 20 days, a 7days buy protection, and a six-month engine and gearbox warranty. A big thanks to Credr.",
    id: 'Abhi '
  },
  {
    name: 'Prithawayan Sinha',
    profile: maleIcon,
    comment: "Credr has a wonderful team. The guys are very cooperative and fast; this is the best part. Always there to help you with every problem. They take care of everything. I seriously recommend coming over to CredR rather than any other second hand bike shop.",
    id: 'Prithawayan'
  },
  {
    name: 'Balaji',
    profile: maleIcon,
    comment: "Hi all, I bought a Honda unicorn in 2019 from CredR. It's really good; I used it for nearly 2years and sold it back to CredR via doorstep service. Checking people and pickup people came on the same day because I wanted to sell within a day. They made the process very simple. I sold it at a good price. Thank you so much for the transparency and support.",
    id: 'Balaji '
  },
  {
    name: 'Abhinav Khare',
    profile: maleIcon,
    comment: "Purchasing from CredR is a win-win deal for the seller as well as the buyer. Proper guidance was given to me to make a relevant purchase. I am happy with the purchase from CredR. Looking forward to good service in future as well for a good experience with their service.",
    id: 'Abhinav '
  }

]

export const TESTIMONIALS_CUSTOMERS_SELL = [
  {
    name: 'Sandhya R',
    profile: femaleIcon,
    comment: 'I posted in CredR for selling my bike. Within a day inspection happened and took the bike. The price quoted by them is fair and we are satisfied. I gave them half an hour to pick my bike as I had my flight and immediately Pradeep came and did all formalities in less than an hour. I strongly suggest CredR for selling bikes as it was hasslefree.',
    bike: 'Sold Honda Dio to CredR',
    id: 'sandhya'
  },
  {
    name: 'Vinod Kumar. M',
    profile: maleIcon,
    comment: `I have sold my bike to CredR company. It was very smooth dealing with them. I just visited their official website and got the customer care number. I called them and they visited at home bike inspection then he called and offered a decent bike price. I got a fair amount and the logistics person came and picked up the vehicle. His behaviour was also good. Within 12hrs I got the payment. Thank You CredR team and head office.`,
    bike: 'Sold Honda Activa to CredR',
    id: 'vinod'
  },
  {
    name: 'Anshul Agrawal',
    profile: maleIcon,
    comment: `Hassle-free services, It is quick and fast. I sold my Activa and got a good price with a quick inspection. They came to my home to complete all the process. No need to go anywhere.`,
    bike: 'Sold Activa to Credr',
    id: 'anshul'
  },
  // {
  //   name: 'Jagadeesh Lepakshi',
  //   profile: maleIcon,
  //   comment: `I sold my bike recently to CredR. My experience: Initial survey was done smoothly. Negotiated amount was credited the next day after the submission of documents. Overall experience with CredR was very good.`,
  //   id: 'jagadeesh'
  // },
  {
    name: 'Dinesh Radhakrishnan',
    profile: maleIcon,
    comment: `I sold my bike to CredR company. Got a decent value for the bike and their service was good. Got the payment within 24 hours.`,
    bike: 'Sold Honda CB Hornet to CredR',
    id: 'dinesh'
  }
]

export const TESTIMONIALS_FRANCHISE = [
  {
    name: 'Vijay Naidu - Franchise Owner, Bengaluru',
    profile: TestimonialIcon1,
    comment: 'Right from finalizing the showroom location, to refurbishing the supply, training the staff and driving sales, CredR Team has been very supportive to us throughout. The kind of assistance they provide makes it a lot easier for me to run the showroom. Proud to be associated with a company that is heading towards making the consumers experience hassle free. Looking forward to a very long term association with CREDR',
    id: 'vijay'
  },
  {
    name: 'Lalita Tholia - Franchise Owner, Jaipur',
    profile: TestimonialIcon2,
    comment: "The greatest thing with CredR is that there is a trust factor here. With CredR, customers confidently make a purchase and recommend it ahead. Associating with such a brand is quite fulfilling in many ways.",
    id: 'lalita'
  },
  {
    name: 'Amol Jadhav - Franchise Owner, Pune',
    profile: TestimonialIcon3,
    comment: "We have been in the two-wheeler sales and service for more than 15 years and we realized that people did not have a reliable source to buy second-hand two-wheelers from. So we associated with CredR and since then we have been able to serve our customers much better.",
    id: 'amol'
  },
  {
    name: 'Akhilesh Garg - Franchise Owner, Delhi',
    profile: TestimonialIcon4,
    comment: "When I was exploring various business opportunities, I came across CredR’s Used Two-wheeler Franchise Concept. I got to know about the massive potential that the used two-wheeler market holds and I am glad I was able to make the right choice.",
    id: 'Akhilesh'
  },
  {
    name: 'Pallavi Zambre - Franchise Owner, Pune (Multiple showrooms)',
    profile: TestimonialIcon,
    comment: "We started a CredR Showroom in May 2018. CredR brought in a new concept of acquiring customers and maintaining a great customer relationship. Everything worked out so well that we immediately decided to launch another one in August 2018.",
    id: 'Pallavi'
  }
]

export const TESTIMONIALS_SERVICE = [
  {
    name: 'Yash',
    profile: ServiceTestimonialYash,
    comment: ' I had a great experience with credr bike service online. I booked a bike service slot at my doorstep. The experts came to my house, picked up the bike, and then after servicing, delivered it to my house. It was really easy and effortless. I enjoyed the swift service. In fact, my bike is performing well. All issues with brakes and engine are resolved.',
    id: 'yash'
  },
  {
    name: 'Mithesh',
    profile: ServiceTestimonialMithesh,
    comment: "I booked a bike service in Bangalore from CredR. The issue was resolved, and I got a good response from the credr team. Everything is online. I really liked how they analyzed the issues and clarified them to me. Highly recommended.",
    id: 'mithesh'
  },
  {
    name: 'Krathik Jain',
    profile: ServiceTestimonialKratikJain,
    comment: " I used their services for the first time, I was a bit skeptical, but to my surprise, it was absolutely fantastic. The technician repaired my bike well. I would recommend credr bike service at doorstep.",
    id: 'karthik'
  },
  {
    name: 'Arun',
    profile: ServiceTestimonialArun,
    comment: "My experience with bike repair by credr has been good. My honda bike was picked up from home, serviced, and delivered back promptly. All the work was nicely explained before it was actually executed. The service was transparent and genuine parts were used. Overall I loved it.",
    id: 'arun'
  },
  {
    name: 'Kamal',
    profile: ServiceTestimonialKamal,
    comment: " I just enjoyed the bike repair service from credr. My Jupiter scooty was creating a lot of issues. After the service, it is smooth and feels new to me. The auto mechanic was decent and reliable, and they used genuine spare parts, and no extra charge was asked. Thank you, credr.",
    id: 'kamal'
  }
]