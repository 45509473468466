import React from 'react'
import { API_ENDPOINTS } from '../../../core/constants/apiEndpoints'
let imageSrcPath= API_ENDPOINTS.IMAGES.IMAGES_BASE_PATH
let seeMoreIcon = imageSrcPath+'/seeMoreIcon.png'

const SeeMore = ({ onClick }) => (
  <div className="card seeMoreCard" onClick={onClick}>
    <div className="seeMoreBikeWrapper">
      <img src={seeMoreIcon} alt="See More" />
    </div>
  </div>
)

export default SeeMore