import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Field, reduxForm } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import Divider from '@material-ui/core/Divider'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import SelectField from '@material-ui/core/Select'
import { setThankYouData } from '../../redux/actions/thankYouAction'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LoaderButton from '../UI/ButtonLoader'
import { createLead } from '../../core/services/leadServices'
import { sellBikeSearch } from '../../redux/actions/sellBikeSearchAction'
import './lead-form.scss'
import validate from './validation'

const RenderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
    <FormControl className='w-100'>
      <TextField
        label={label}
        error={touched && Boolean(error)}
        {...input}
        {...custom}
      />
      <FormHelperText style={{ color: '#c00' }}>{touched && error}</FormHelperText>
    </FormControl>
  )

const RenderAutoComplete = ({
  input,
  label,
  options
}) => (
    <Autocomplete
      freeSolo
      disableClearable
      onInputChange={(_, value) => input.onChange(value)}
      options={options}
      getOptionLabel={bike => bike.bikeName}
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
    />
  )

const RenderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
    <FormControl className='w-100'>
      <InputLabel style={{ color: error && touched ? '#c00' : '' }}>{label}</InputLabel>
      <SelectField
        label={label}
        error={touched && Boolean(error)}
        {...input}
        onChange={(event) => input.onChange(event.target.value)}
        children={children}
        {...custom}
      />
      <FormHelperText style={{ color: '#c00' }}>{touched && error}</FormHelperText>
    </FormControl>
  )

const onlyContactNumber = (value) => {
  if (!value) {
    return value
  }
  let onlyContactNumbers = value.replace(/[^\d]/g, '')
  onlyContactNumbers.replace(/(?!^)+/g, '')
  return onlyContactNumbers.slice(0, 10)
}

const DirectLeadForm = ({ open, handleSubmit, onClose, dispatch, valid }) => {

  const bikes = useSelector(state => state.sellBikeSearch.sellBikes)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  // const cities = useSelector(state => state.cities.cityList)

  const handleForm = data => {
    if (valid) {
      setIsLoading(true)
      const { customerName, customerMobileNumber, cityId, makeName } = data
      const payload = { subType: 'B2C', customerName, customerMobileNumber, cityId }
      if (makeName) {
        payload.makeName = makeName
      }
      createLead(payload)
        .then(apiResponse => {
          if (apiResponse.isValid) {
            setIsLoading(false)
            onClose()
            dispatch(setThankYouData({ name: customerName, mobileNumber: customerMobileNumber, cityId, bike: makeName ? makeName : '' }))
            history.push('/sellbikes/thank-you')
          }
        })
        .catch(error => {
          setIsLoading(false)
          onClose()
        })
    }
  }

  const handleBikeNameChange = searchKey => {
    dispatch(sellBikeSearch(searchKey))
  }

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        Please share your details
        <span onClick={onClose} className="float-right" style={{ cursor: 'pointer' }}>
          &times;
        </span>
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(handleForm)}>
        <DialogContent>
          <Field
            name="customerName"
            component={RenderTextField}
            label="Name"
          />
          <Field
            name="customerMobileNumber"
            component={RenderTextField}
            normalize={onlyContactNumber}
            label="Mobile Number"
          />
          <Field
            name="cityId"
            component={RenderSelectField}
            label="Select City"
          >
            <MenuItem value={1}>Bangalore</MenuItem>
            {/* {
              Boolean(cities.length) && cities.map(city => <MenuItem value={city.cityId} key={city.cityId}>{city.cityName}</MenuItem>)
            } */}
          </Field>
          <Field
            name="makeName"
            component={RenderAutoComplete}
            label="Bike Name"
            options={bikes}
            onChange={handleBikeNameChange}
          />
        </DialogContent>
        <Divider style={{ marginTop: 15 }} />
        <DialogActions>
          <LoaderButton isLoading={isLoading} className="sellBtn">Submit</LoaderButton>
        </DialogActions>
      </form>
    </Dialog >
  )
}

export default reduxForm({ form: 'directLead', validate })(DirectLeadForm)