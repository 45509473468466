import React, { Fragment } from 'react'
import CountUp from 'react-countup'
import { getAmount } from '../../core/utility'
import './rating.scss'

const CITY_COUNT = 4
const CUSTOMER_COUNT = 300000

const getCutomersCount = (value) => {
  if (value === CUSTOMER_COUNT)
    return `${getAmount(value)}+`
  return getAmount(value)
}

const getCitiesCount = (value) => {
  if (value === CITY_COUNT)
    return `${value}+ Cities & Growing`
  return value
}

const CredRRatings = () => (
  <Fragment>
    <div className="sectionBlockCustomMarginTop countDownWrapper xsMob">
      <div className="card shadow-none">
        <div className="card-body" >
          <div id="countDown">
            <div className="countDown">
              <div className="col-sm-3 col-md-3 text-center seprator">
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title font-semibold font22">Customers</h3>
                    <h2 className="counter font-bold" >
                      <CountUp end={CUSTOMER_COUNT} useEasing={false} duration={4.5} formattingFn={getCutomersCount} />
                    </h2>
                    <p className="card-text">Customers delighted with our unmatched benefits and offerings</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 text-center seprator">
                <div className="card" >
                  <div className="card-body">
                    <a href="https://www.facebook.com/credr/" target="_blank" rel="noopener noreferrer" >
                      <h3 className="card-title font-semibold font22"><i className="facebook-icon" ></i>Ratings 4.7 / 5</h3>
                    </a>
                    <i className="ratings-icon"></i>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 text-center seprator">
                <div className="card">
                  <div className="card-body">
                    <a href="https://g.co/kgs/gfXrpS" target="_blank" rel="noopener noreferrer" >
                      <h3 className="card-title font-semibold font22"><i className="google-icon" ></i>Reviews 4.7 / 5</h3></a>
                    <i className="ratings-icon"></i>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 text-center">
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title font-semibold font22"> </h3>
                    <h2 className="counter font-bold citiesCount" city-count="6" >
                      <CountUp end={CITY_COUNT} useEasing={false} duration={4.5} formattingFn={getCitiesCount} />
                    </h2>
                    {/* <p className="card-text">10+ Showrooms across Bangalore &amp; Pune, and soon we are expanding to more cities.</p> */}
                    <p className="card-text">Across Bangalore &amp; Pune, and soon we are expanding to more cities.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="sectionBlockCustomMarginTop countDownWrapper trustCustomHide homepagePaddingMuted">
      <div className="card shadow-none">
        <div className="card-body" >
          <div id="countDown">
            <div className="col-12 text-center float-left">
              <h2 className="card-title font-bold font22"><span style={{minWidth:"86px",display:"inline-block"}}><CountUp className="colorRed" useEasing={false} end={CUSTOMER_COUNT} duration={4.5} formattingFn={getCutomersCount} /></span> Customers Trust Us!</h2>
            </div>
            <div className="countDown mb-3">
              <div className="col-6 text-center seprator px-0">
                <div className="card" >
                  <div className="card-body py-0">
                    <a href="https://www.facebook.com/credr/" target="_blank" rel="noopener noreferrer" >
                      <h3 className="card-title font-semibold font22">
                        <i className="facebook-icon" ></i>Ratings 4.7 / 5</h3>
                    </a>
                    <i className="ratings-icon"></i>
                  </div>
                </div>
              </div>
              <div className="col-6 text-center px-0">
                <div className="card">
                  <div className="card-body py-0">
                    <a href="https://g.co/kgs/gfXrpS" target="_blank" rel="noopener noreferrer" >
                      <h3 className="card-title font-semibold font22">
                        <i className="google-icon" ></i>Reviews 4.7 / 5</h3>
                    </a>
                    <i className="ratings-icon"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center float-left">
              <h2 className="card-title font-bold font22 mb-0 citiesCount"><span ><CountUp end={CITY_COUNT} useEasing={false} duration={4.5} formattingFn={getCitiesCount} /></span><br/> CredR Showrooms</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default CredRRatings