import React from 'react'
import USPCard from './USP-Card'
import { Link } from 'react-router-dom'
import { getUrlSanatizedString } from '../../core/utility'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const CredRUSP = ({ homeDeliveryOnly = false }) => {

  const location = useLocation()
  const careSearch =  location.search 
  
  const currentCity = useSelector(state => state.currentCity.currentCity)

  const getUrl = city => {
    return city ? `/all-used-bikes-in-${getUrlSanatizedString(city)}` : '/all-used-bikes'
  }
  
  const getUrlCareApp = city => {
    return city ? `/all-used-bikes-in-${getUrlSanatizedString(city)}/${careSearch}` : `/all-used-bikes/${careSearch}`
  }

  return (
    <div className="sectionBlockCustomMarginTop">
      <div className="row">
        <div className="col-sm-12 homepagePaddingMuted">
          <div className="card sectionCustomPaddingBtm">
            <div className="card-body carBodyMainHd cardConatiner" id="credrPromises">
              <h2 className="font-bold text-center sectionCustomPaddingBtm mb-0">
                CREDR SHOWROOMS - Trusted Showroom For Used Bikes
            </h2>
              <div className="row text-center">
                <div className="col-sm-6 col-md-4 mb-3">
                  <USPCard
                    icon="expertIcon"
                    heading="Certified by Auto Experts on 120+ Inspection Points"
                    discription="Choose from 100% Certified Bikes only. All bikes are thoroughly tested and certified by Auto Experts who come with 8+ years of Industry Experience."
                  />
                </div>
                <div className="col-sm-6 col-md-4 mb-3">
                  <USPCard
                    icon="ribbonIcon"
                    heading={homeDeliveryOnly ? "Free 12 Months Comprehensive Warranty" : "Free 6 Months Comprehensive Warranty worth Rs.5000"}
                    discription="Secure your bike for the future.Experience the best in class warranty covering the critical bike parts including Engine & Gearbox. Now that’s something you can’t put a price on."
                  />
                </div>
                <div className="col-sm-6 col-md-4 mb-3">
                  <USPCard
                    icon="credrCheckIcon"
                    heading="Free 7-Day Buy Protect"
                    discription="Get covered with our flagship product.Any unforeseen issues you face in a week's time will be resolved free of cost. Ride out worry-free on your CredR bike."
                  />
                </div>
                <div className="w-100 text-center viewStoreBtnCustomMargin">
                  {/* <Link to={getUrl(currentCity)}> */}
                  <Link to={ careSearch ? getUrlCareApp(currentCity) : getUrl(currentCity)}>
                    <div className='btn btn-primary' role="button">VIEW CREDR SHOWROOM BIKES</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}


export default CredRUSP