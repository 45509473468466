import React from 'react'
import LazyImage from '../../Lazy-Image'
import Zoom from 'react-reveal/Zoom'

const TestimonialCard = ({ profile, name, comment }) => (
  <Zoom>
    <div>
      <div className="card h-100 d-inline-block ">
        <div className="card-body reviewCardPadding customCardPadding">
          <div className="testiIcons rounded-circle">
            <LazyImage src={profile} className="img-fluid" alt="Profile" />
          </div>
          <h3 className="card-title font20 font-semibold marginBottomTitle" >{name}</h3>
          <p className="card-text" >{comment}</p>
        </div>
      </div>
    </div>
  </Zoom>
)

export default TestimonialCard