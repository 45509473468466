import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAllBanners } from '../../redux/actions/bannerAction'
import Slider from 'react-slick'
import './carousel.scss'
import ReactGA from 'react-ga'
import { getUrlSanatizedString } from '../../core/utility'
import { withRouter } from 'react-router-dom'
import { CAROUSEL_SETTINGS } from '../../core/constants/settings'
import DirectLeadForm from '../DirectLeadForm'


// const desktopBannerNew=["https://d1xsneilabfr60.cloudfront.net/1614163090391-Sell-Web-Banner_desktop_1.jpg"]
// const mobileBannerNew=["https://d1xsneilabfr60.cloudfront.net/1632476749830-Refurbished-Bike-banner-Mobile23-sept.jpg","https://d1xsneilabfr60.cloudfront.net/1634887674751-Mobile.jpg","https://d1xsneilabfr60.cloudfront.net/1615273108862-Mobile-02.jpg","https://d1xsneilabfr60.cloudfront.net/1619078747660-Mobile-02.png","https://d1xsneilabfr60.cloudfront.net/1633939983662-Mobile-2.png","https://d1xsneilabfr60.cloudfront.net/1632476690762-Sell-Web-Banner_Mob_1.jpg","https://d1xsneilabfr60.cloudfront.net/1630832690791-Mobile_banner-07.jpg","https://d1xsneilabfr60.cloudfront.net/1654746290038-Gold_web_banners_Mobile.jpg","https://d1xsneilabfr60.cloudfront.net/1590645440085-Mob-Banner_2.png","https://d1xsneilabfr60.cloudfront.net/1654746198180-Direct_web_banners_Mobile.jpg"]
class CredRCarousel extends Component {

  state = {
    isOpen: false,
  }

  // componentDidMount() {
  //   this.props.dispatch(getAllBanners())
  // }

  gotoPage = (city, banner) => {
    const { target } = banner
    const { userAddress } = this.props
    const GAEvent = { category: 'Banner Click' }
    const { history } = this.props
    if (target === 'sellOnly') {
      GAEvent.action = "Sell Your Bike"
      history.push('/sellbikes')
    }
    else if (target === 'showroomLocator') {
      GAEvent.action = "Showroom Locator"
      history.push('/showroom-locator')
    }
    else if (target === 'franchise') {
      GAEvent.action = "Own a Franchise"
      history.push('/franchise')
    }
    else if (target === 'bangalore-honda-activa') {
      GAEvent.action = "Bangalore Honda Activa Starting at Rs. 54,990"
      history.push('/all-used-scooters-in-Bangalore-Indira-Nagar/Honda-Activa?f=eyJidWRnZXRNaW5NYXhMaXN0IjpbeyJtaW5CdWRnZXQiOiI0NTAwMSIsIm1heEJ1ZGdldCI6IjU1MDAwIn0seyJtaW5CdWRnZXQiOiI1NTAwMSIsIm1heEJ1ZGdldCI6IjEwMDAwMCJ9XX0=')
    }
    else if (target === 'jev') {
      GAEvent.action = "Electric Vehicles"
      history.push('/electric-vehicles')
    }
    else if (target === 'direct_lead_form') {
      GAEvent.action = "Lead Form"
      this.setState({ isOpen: true })
    }
    else if (target === 'blog') {
      GAEvent.action = "CredR Blog"
      window.open('https://www.credr.com/blog/')
    }
    else if (target === 'service') {
      GAEvent.action = "Door Step Service"
      history.push('/door-step-service')
    }
    else if (target === 'social-mob') {
      GAEvent.action = "Social Mob"
      window.open("https://socialmob.page.link/u9Qn")
    }
    else if (target === 'Exchange') {
      GAEvent.action = "Exchange Page"
      history.push('/sell-your-bike-buy-new-bike')
    }
    else if (target === 'Exchange_Yamaha') {
      if (userAddress && userAddress === 'Bengaluru') {
        GAEvent.action = "Exchange FB Page"
        history.push('/sell-your-bike-buy-new-bike/banglore/yamaha')
      }
      else {
        GAEvent.action = "Exchange FB Page"
        history.push('/sell-your-bike-buy-new-bike')
      }
    }
    else {
      GAEvent.action = "Banner Click"
      if (city)
        history.push(`/used-bikes-in-${getUrlSanatizedString(city)}`)
      else
        history.push('/all-used-bikes')
    }
    ReactGA.event(GAEvent)
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { desktopBanner, mobileBanner, selectedCity, device, showApp } = this.props
    const { isOpen } = this.state
    console.log("new banner deployed")
    return (
      <div className={`${device === 'phone'?"bg-carousal-placeholder-mobile":"bg-carousal-placeholder-desktop"}`}>
        {
          device === 'phone' ?
            <Slider {...CAROUSEL_SETTINGS} className="slider desktop-hidden">
              {
                Boolean(mobileBanner.length) && mobileBanner.map((banner) => (
                  <div key={banner.url} className="list" onClick={() => this.gotoPage(selectedCity, banner)}>
                    <img src={banner.url} alt="Banner" className="img-fluid" />
                  </div>
                ))
              }
            </Slider> :
            <Slider {...CAROUSEL_SETTINGS} className="slider mobile-hidden">
              {
                Boolean(desktopBanner.length) && desktopBanner.map(banner => (
                  <div key={banner.url} className="list" onClick={() => this.gotoPage(selectedCity, banner)}>
                    <img src={banner.url} alt="Banner" className="img-fluid" />
                  </div>
                ))
              }
            </Slider>
        }
        {
          isOpen &&
          <DirectLeadForm
            open={isOpen}
            onClose={this.handleClose}
          />
        }
      </div>
      
    )
  }
}

const mapStateToProps = (state) => ({
  desktopBanner: state.banner.desktopBanner,
  mobileBanner: state.banner.mobileBanner,
  selectedCity: state.currentCity.currentCity,
  device: state.deviceDetail.device,
  showApp: state.showAppReducer.show
})

export default connect(mapStateToProps)(withRouter(CredRCarousel))