import React, { Fragment } from 'react'
import LazyImage from '../Lazy-Image'
import { getAmount } from '../../core/utility'
import Zoom from 'react-reveal/Zoom';

const BikeCard = ({ bike }) => {
  return (
    <Zoom>
      <div className="card" id="bikeCardLink">
        <div className="cardBikeImgWrapper">
          <LazyImage src={bike.imageUrlList && bike.imageUrlList.length && bike.imageUrlList[0]} alt={bike.make + ' ' + bike.model + ' ' + bike.variant} className='card-img-top' />
          {bike.storeType === '1' && <div className="credrWarranty"></div>}
        </div>
        <div className="card-body">
          <h5 className="font-semibold font16 bikeHdMarginBtm">
            {bike.make} {bike.model} {bike.variant}
          </h5>
          <h5 className="font-semibold font16 ">
            {
              bike.displayPrice ? <span className="greenColor">@ &#8377;{getAmount(bike.displayPrice)}</span> : <Fragment />
            }
            <span className="font14 font-regular pl-2 priceStrike">&#8377;{getAmount(bike.inventoryPrice)}</span>
          </h5>
        </div>
      </div>
    </Zoom>
  )
}

export default BikeCard
