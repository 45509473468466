import React, { Component, Fragment } from 'react'
import Slider from 'react-slick'
import { Link, withRouter } from 'react-router-dom'
import BikeCard from '../../Bike-Card'
import SeeMore from '../../Partials/See-More'
import { getCity } from '../../../core/services/storageService'
import { getUrlSanatizedString, navigateToBike, getStringyfyUrlParameter } from '../../../core/utility'
import Loader from 'react-loader-spinner'
import './bikeSlider.scss'

class BikeSlider extends Component {

  state = {
    index: ''
  }

  componentDidUpdate(prevProps) {
    if (this.props.tab !== prevProps.tab) {
      if (this.slider) {
        this.slider.slickGoTo(0)
      }
    }
  }

  linkToSeeMore = () => {
    const { budget, history } = this.props
    getCity() ? history.push(`/all-used-bikes-in-${getUrlSanatizedString(getCity().cityName)}?f=${getStringyfyUrlParameter({ budgetMinMaxList: budget })}`) : history.push(`/all-used-bikes?f=${getStringyfyUrlParameter({ budgetMinMaxList: budget })}`)
  }

  render() {
    const { bikeList, loading } = this.props
    const budgetBikeSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4.025,
      slidesToScroll: 1,
      pauseOnHover: true,
      swipeToSlide: true,
      lazyLoad: "ondemand",
      afterChange: (index) => {
        var indexCount = 4 + index
        this.setState({ index: indexCount });
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3.025
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3.025,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2.025,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.25,
            slidesToScroll: 1
          }
        }
      ]
    }

    return (
      <div id="popularUsedBikes" style={loading ? { textAlign: 'center' } : {}}>
        {
          loading ?
            <Loader style={{ display: 'inline-block' }} type="CradleLoader" height="1000" width="1000" />
            : (
              <Slider ref={c => (this.slider = c)} {...budgetBikeSettings} className="slider">
                {
                  bikeList.length ? bikeList.map((bike) => {
                    return (
                      <div key={bike.inventoryId} className="card-slider-link">
                        <Link to={navigateToBike(bike)} >
                          <BikeCard bike={bike} />
                        </Link>
                      </div>
                    );
                  }) : <Fragment />
                }
                {bikeList.length >= 8 ? <SeeMore onClick={this.linkToSeeMore} /> : <Fragment />}
              </Slider>
            )
        }
      </div>
    )

  }
}

export default withRouter(BikeSlider)