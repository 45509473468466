import { getBudgetBike } from '../../core/services/bikeServices'
import { BUDGET_BIKE_LIST_ACTION } from '../../core/constants/actions'

export function getAllBudgetBikes(budget = {}) {
  return dispatch => {
    dispatch(request())
    return getBudgetBike(budget)
      .then(apiResponse => {
        if (apiResponse.isValid) {
          const { bikeUnderFiftyK, bikeUnderThirtyK, bikeUnderTwentyK } = apiResponse.budgetResponseBean
          dispatch(success({
            bikeUnderFiftyK,
            bikeUnderThirtyK,
            bikeUnderTwentyK
          }))
        } else {
          dispatch(failure({ error: apiResponse.message }))
        }
      })
  }

  function request() { return { type: BUDGET_BIKE_LIST_ACTION.REQUEST } }
  function success(budgetBikes) { return { type: BUDGET_BIKE_LIST_ACTION.SUCCESS, payload: { ...budgetBikes } } }
  function failure(error) { return { type: BUDGET_BIKE_LIST_ACTION.FAILURE, payload: { ...error } } }
}