import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAllBudgetBikes } from '../../redux/actions/budgetBikeListAction'
import BikeSlider from './Bike-Slider'
import { getCity } from '../../core/services/storageService'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const tabStyles = {
  selected: 'tab-selected',
  root: 'budget-tab',
  wrapper: 'tab-heading'
}

class BudgetBikes extends Component {

  state = {
    tab: 1
  }

  componentDidMount() {
    if (getCity()) {
      const payload = { cityId: getCity().cityId }
      this.props.dispatch(getAllBudgetBikes(payload))
    } else {
      this.props.dispatch(getAllBudgetBikes())
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCity !== prevProps.selectedCity) {
      if (getCity()) {
        const payload = { cityId: getCity().cityId }
        this.props.dispatch(getAllBudgetBikes(payload))
      } else {
        this.props.dispatch(getAllBudgetBikes())
      }
    }
  }

  switchTab = tabCount => {
    this.setState({ tab: tabCount })
  }

  render() {
    const { selectedCity, bikeUnderFiftyK, bikeUnderThirtyK, isLoading } = this.props
    const { tab } = this.state
    return (
      <div className="sectionBlockCustomMarginTop" >
        <div className="row">
          <div className="col-sm-12 homepagePaddingMuted">
            <div className="card sectionCustomPaddingBtm">
              <div className="card-body carBodyMainHd cardConatiner" >
                {
                  selectedCity ?
                    <h2 className="font-bold text-center mb-0">Budget Bikes in {selectedCity}</h2> :
                    <h2 className="font-bold text-center mb-0">Budget Bikes</h2>
                }
                <Tabs
                  value={tab}
                  classes={{ root: 'tab-track' }}
                  onChange={(e, tabCount) => this.switchTab(tabCount)}
                >
                  <Tab
                    value={1}
                    label="21k - 30k"
                    disableRipple
                    classes={tabStyles}
                  />
                  <Tab
                    value={2}
                    label="31k - 50k"
                    disableRipple
                    classes={tabStyles}
                  />
                </Tabs>
                <div className="tab-content" id="budgetBikesTabContent">
                  {
                    tab === 1 ?
                      <BikeSlider bikeList={bikeUnderThirtyK} loading={isLoading} budget={[{ minBudget: "20001", maxBudget: "30000" }]} tab='21k' /> :
                      <BikeSlider bikeList={bikeUnderFiftyK} loading={isLoading} budget={[{ minBudget: "30001", maxBudget: "50000" }]} tab='31k' />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedCity: state.currentCity.currentCity,
  bikeUnderTwentyK: state.budgetInventory.bikeUnderTwentyK,
  bikeUnderFiftyK: state.budgetInventory.bikeUnderFiftyK,
  bikeUnderThirtyK: state.budgetInventory.bikeUnderThirtyK,
  isLoading: state.budgetInventory.isLoading
})

export default connect(mapStateToProps)(BudgetBikes)
