import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Slider from 'react-slick'
import { POPULAR_BIKE_SETTINGS } from '../../core/constants/settings'
import TopSellingBikeCard from './TopSellingBike-Card'
import { getAllTopSellingBikes } from '../../redux/actions/topSellingBikesAction'
import { getCity } from '../../core/services/storageService'
import Loader from 'react-loader-spinner'
import { getUrlSanatizedString } from '../../core/utility'
import './popular.scss'

class PopularBikes extends Component {

  componentDidMount() {
    if (getCity())
      this.props.dispatch(getAllTopSellingBikes(getCity().cityId))
    else
      this.props.dispatch(getAllTopSellingBikes())
  }

  componentDidUpdate(prevProp) {
    if (this.props.currentCity !== prevProp.currentCity) {
      if (getCity())
        this.props.dispatch(getAllTopSellingBikes(getCity().cityId))
      else
        this.props.dispatch(getAllTopSellingBikes())
    }
  }

  getBikeUrl = (bike) => {
    const { currentCity } = this.props
    const { bikeMakeName, bikeModelName, inventoryType, vehicleName } = bike
    let url = ''
    if (currentCity)
      url = url.concat(`/all-used-<VEHICLE>-in-${getUrlSanatizedString(currentCity)}/`)
    else
      url = url.concat(`/all-used-<VEHICLE>/`)
    url = url.replace('<VEHICLE>', inventoryType.toLowerCase().concat('s'))
    return url.concat(`${getUrlSanatizedString(bikeMakeName)}-${getUrlSanatizedString(bikeModelName)}`)
  }

  getBikeUrlCareApp = (bike) => {
    const careSearch =  this.props.location.search
    const { currentCity } = this.props
    const { bikeMakeName, bikeModelName, inventoryType } = bike
    let url = ''
    if (currentCity)
      url = url.concat(`/all-used-<VEHICLE>-in-${getUrlSanatizedString(currentCity)}/${careSearch}`)
    else
      url = url.concat(`/all-used-<VEHICLE>/${careSearch}`)
    url = url.replace('<VEHICLE>', inventoryType && inventoryType.toLowerCase().concat('s'))
    return url.concat(`${getUrlSanatizedString(bikeMakeName)}-${getUrlSanatizedString(bikeModelName)}/${careSearch}`)
  }

  render() {
    const careSearch =  this.props.location.search
    const { topSellingBikes, currentCity, isLoading, selling } = this.props
    return (
      <div className="row sectionBlockCustomMarginTop">
        <div className="col-sm-12 homepagePaddingMuted">
          <div className={selling ? "enhancedPopularSell card sectionCustomPaddingBtm" : "card sectionCustomPaddingBtm"}>
            <div className="card-body carBodyMainHd cardConatiner" id="popularUsedBikes" style={isLoading ? { textAlign: 'center' } : {}} itemScope itemType="https://credr.com">
              {
                currentCity ?
                  <h2 className="font-bold text-center sectionCustomPaddingBtm"> {selling ? `Sell your Old Two-wheeler and Upgrade to a Refurbished CredR Used Two-wheeler ${currentCity}` : `Top Selling Bikes in ${currentCity}`} </h2>
                  : <h2 className="font-bold text-center sectionCustomPaddingBtm">{selling ? `Sell your Old Two-wheeler and Upgrade to a Refurbished CredR Used Two-wheeler` : `Top Selling Bikes`}</h2>
              }
              {
                isLoading ?
                  <Loader style={{ display: 'inline-block' }} type="CradleLoader" height="1000" width="1000" />
                  :
                  <Slider {...POPULAR_BIKE_SETTINGS} className="slider">
                    {
                     topSellingBikes && topSellingBikes.length && topSellingBikes.map(bike => {
                        return (
                          <Link key={bike.imageUrlList && bike.imageUrlList.length && bike.imageUrlList[0]} to={careSearch ? this.getBikeUrlCareApp({ bike, careSearch }) : this.getBikeUrl(bike) } className="no-link-decoration">
                           {/* <Link key={bike.bikeImage} to={this.getBikeUrl(bike)} className="no-link-decoration"> */}
                            <TopSellingBikeCard bike={bike} />
                          </Link>
                        )
                      })
                    }
                  </Slider>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  topSellingBikes: state.topSellingBikes.topSellingBikes,
  isLoading: state.topSellingBikes.isLoading,
  currentCity: state.currentCity.currentCity
})

export default connect(mapStateToProps)(withRouter(PopularBikes))
