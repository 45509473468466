const validate = values => {
  const errors = {}
  if (!values.customerName)
    errors.customerName = '* Please Enter Name'
  if (!values.cityId)
    errors.cityId = '* Please Select City'
  if (!values.customerMobileNumber)
    errors.customerMobileNumber = '* Please Enter Mobile Number'
  else if (values.customerMobileNumber.length !== 10)
    errors.customerMobileNumber = '* Please Enter Valid Mobile Number'

  return errors
}

export default validate