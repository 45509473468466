import React from 'react'
import Slider from 'react-slick'
import { TESTIMONIALS_SETTINGS } from '../../core/constants/settings'
import TestimonialCard from './Testimonial-Card'
import './testimonial.scss'

const Testimonials = ({ testimonials }) => (
  <div className="sectionCustomMarginTop" >
    <div className="row">
      <div className="col-sm-12">
        <h2 className="font-bold text-center sectionCustomPaddingBtm mb-0" >Customer Testimonial</h2>
        <div id="Testimonial" >
          <div className="text-center">
            <Slider {...TESTIMONIALS_SETTINGS} className="slider">
              {
                Boolean(testimonials.length) &&
                testimonials.map(tesimonial =>
                  <TestimonialCard key={tesimonial.id} {...tesimonial} />
                )
              }
            </Slider>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Testimonials