import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const ButtonLoader = ({ isLoading, children, className, type = "submit" }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button type={type} className={`${className ? `${className} btn-primary` : `btn-primary`}`} disabled={isLoading}>{children}</button>
      {isLoading && <CircularProgress style={{ width: '25px', position: 'absolute', top: '50%', right: '50%', marginTop: '-12px', marginRight: '-12px', height: '25px', color: '#DA2128' }} />}
    </div>
  )
}

export default ButtonLoader